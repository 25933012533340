import { useNavigate } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { type QuickLinksStoryblok } from '#types/component-types-sb'
import PlentyButtonsAndLinks from '../typography/plenty-buttons-and-links'
import { Icon } from '../ui/icon'

const QuickLinks = ({ blok }: QuickLinksStoryblok) => {
	const navigate = useNavigate()
	const { trackAmplitudeEvent } = useAmplitudeContext()

	return (
		<>
			<div {...storyblokEditable(blok)}>
				<div
					onClick={() => navigate(blok.navigation.url)}
					onClickCapture={() => {
						trackAmplitudeEvent('quick link clicked', {
							link: blok.text,
							linkUrl: blok.navigation.url,
						})
					}}
					className="relative flex max-h-[48px] w-full justify-between overflow-hidden rounded-sm bg-beige-50 px-4 py-3"
				>
					<div className="absolute bottom-0 left-0 z-0 flex w-full justify-center md:hidden">
						<Icon
							name="little-bump"
							className=" w-full text-beige-100 opacity-[0.05] "
							size="lg"
						/>
					</div>
					<PlentyButtonsAndLinks className="z-10">
						{blok.text}
					</PlentyButtonsAndLinks>
					<div className="z-10 flex items-center gap-4">
						<div className="relative flex items-center justify-center">
							{/* White circle container becomes the relative parent */}
							<div className="relative rounded-full bg-white p-5">
								{/* Image is absolutely positioned: bottom center inside the circle */}
								<img
									src={blok.image.filename}
									alt={blok.image.alt || ''}
									className="absolute bottom-0 left-1/2 z-10 -mb-1 max-h-[44px] w-auto -translate-x-1/2 transform object-contain"
									style={
										blok.pushImageToSides !== undefined &&
										blok.pushImageToSides !== null
											? { marginLeft: `${blok.pushImageToSides}px` }
											: undefined
									}
								/>
							</div>
						</div>
						{/* Icon container with an explicit margin to ensure it's 16px away from the white circle */}
						<div className="ml-4">
							<Icon size="lg" name="right-arrow" aria-hidden="true" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default QuickLinks

import { storyblokEditable } from '@storyblok/react'
import PlentyHeading from '#app/components/typography/plenty-heading'
import { type HeadingStoryblok } from '#types/component-types-sb'

const HeadingBlok = ({ blok }: HeadingStoryblok) => {
	// Render both mobile and desktop headings
	return (
		<>
			<div {...storyblokEditable(blok)}>
				<PlentyHeading
					as={blok.fontSizeDesktop}
					id={blok._uid}
					className={`text-${blok.textColor} font-${blok.weight}`}
				>
					{blok.heading}
				</PlentyHeading>
			</div>
		</>
	)
}

export default HeadingBlok

import { Await } from '@remix-run/react'
import React from 'react'

import { LoadingSpinner } from '#app/components/ui/spinner'
import ErrorPage from '#app/features/checkout/components/error-page'
import { type Outfit } from '#types/outfit.ts'
import ShopTheLookSimpleCarouselComponent from './shop-the-look-simple-carousel-component'

interface ShopTheLookSimpleCarouselProps {
	outfitsByStyle: Outfit[]
	textColorAmountOfProducts?: string
}

const ShopTheLookSimpleLandingCarousel = ({
	outfitsByStyle,
	textColorAmountOfProducts,
}: ShopTheLookSimpleCarouselProps) => {
	return (
		<>
			<React.Suspense fallback={<LoadingSpinner />}>
				<Await resolve={outfitsByStyle} errorElement={<ErrorPage />}>
					{outfitsByStyle.length > 0 && (
						<div className="space-y-4 md:space-y-8 ">
							<ShopTheLookSimpleCarouselComponent
								outfitsByStyle={outfitsByStyle}
								textColorAmountOfProducts={textColorAmountOfProducts}
							/>
						</div>
					)}
				</Await>
			</React.Suspense>
		</>
	)
}

export default ShopTheLookSimpleLandingCarousel

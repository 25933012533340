import PlentyBody from '../typography/plenty-body'

type StoryblokProductSliderProps = {
	blok: {
		category: string | string[]
		sliderSize: number
		store?: string[]
	}
}

export default function StoryblokProductSlider({
	blok,
}: StoryblokProductSliderProps) {
	return (
		<div>
			{/*Outdated component  */}{' '}
			<PlentyBody>Product slider is outdated</PlentyBody>
		</div>
	)
}

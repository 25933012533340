import { Link } from '@remix-run/react'
import { useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '#app/components/ui/tabs'
import { type PlentyProduct, type ClerkProduct } from '#types/product'
import { ProductCarousel } from './product-carousel'
import PlentyButtonsAndLinks from './typography/plenty-buttons-and-links'
import PlentyHeading from './typography/plenty-heading'
import { Icon } from './ui/icon'

type TabConfig = {
	label: string
	key: string
	linkValue: string
	products?: {
		clerkProducts?: ClerkProduct[]
		commercetoolsProducts?: PlentyProduct[]
	}
}

type TabProductCarouselProps = {
	tabs: TabConfig[]
	title?: string
	subtitle?: string
	linkText?: string
	linkUrl?: string
	storeParams?: string[]
	smallVersion?: boolean
    twoRows?: boolean
}

export function TabProductCarousel({
	tabs,
	title,
	subtitle,
	linkText,
	linkUrl,
	storeParams,
	smallVersion,
    twoRows,
}: TabProductCarouselProps) {
	const defaultTab = tabs[0]?.key || ''
	const [activeTab, setActiveTab] = useState(defaultTab)

	if (!tabs.length) return null

	const buildLinkUrl = (tabKey: string) => {
		const tab = tabs.find(it => it.key == tabKey)
		if (!tab) return ''
		const symbol = tab.linkValue.includes('?') ? '&' : '?'
		return `/products/${tab.linkValue}${storeParams?.length ? `${symbol}store=${storeParams.join(',')}` : ''}`

	}

	return (
		<Tabs defaultValue={defaultTab} onValueChange={setActiveTab} className="w-full">
			<div className="flex flex-col space-y-4">
				<div className="flex flex-wrap md:w-full justify-between md:flex-row md:items-end md:pr-[var(--plenty-padding)]">
					<div>
						<PlentyHeading as="h7">{title}</PlentyHeading>
						<TabsList className="flex w-fit">
						{tabs.map(tab => (
							<TabsTrigger key={tab.key} value={tab.key} className="flex-1">
								{tab.label}
							</TabsTrigger>
						))}
						</TabsList>
					</div>
                    <div>
                    {linkText && (
							<Link
								className="group relative flex items-center"
								to={buildLinkUrl(activeTab)}
							>
								<PlentyButtonsAndLinks as="btn-sm" className="relative">
									{linkText}
								</PlentyButtonsAndLinks>
								<span className="absolute bottom-0 left-0 h-[4px] w-full bg-beige-50 transition-colors duration-300 group-hover:bg-beige-90" />
								<Icon
									className="ml-2 h-4 w-4 transform transition-transform duration-300 group-hover:translate-x-1"
									name="right-arrow"
								/>
							</Link>
						)}
                    </div>
				</div>

				{tabs.map(tab => (
					<TabsContent key={tab.key} value={tab.key}>
						{tab.products && (
							<ProductCarousel
								clerkProducts={tab.products.clerkProducts}
								commercetoolsProducts={tab.products.commercetoolsProducts}
								title={title}
								subtitle={subtitle}
								linkText={linkText}
								linkUrl={linkUrl}
								storeParams={storeParams}
								smallVersion={smallVersion}
								tabsOption={true}
                                twoRows={twoRows}
							/>
						)}
					</TabsContent>
				))}
			</div>
		</Tabs>
	)
}
import { useEffect, useState } from 'react'
import { TabProductCarousel } from '#app/components/tab-product-carousel'
import { type ProductCarouselStoryblok } from '#types/component-types-sb'
import { type ClerkProduct } from '#types/product'

type TabConfig = {
	label: string
	key: string
	linkValue: string
	content: ProductCarouselStoryblok[]
}

type StoryblokTabProductCarouselProps = {
	blok: {
		tabs: TabConfig[]
		title?: string
		linkText?: string
		twoRows?: boolean
	}
}

export default function StoryblokTabProductCarousel({
	blok,
}: StoryblokTabProductCarouselProps) {
	const [tabProducts, setTabProducts] = useState<Map<string, ClerkProduct[]>>(new Map())

	const fetchProductsForTab = async (tabConfig: TabConfig) => {
		if (!tabConfig.content[0].recommendation) return []

		const { category, store, recommendation, on_sale, brand } = tabConfig.content[0]
		const categoryParams = category
			?.map(cat => `categoryKey=${encodeURIComponent(cat)}`)
			.join('&')
		const storeParams =
			store?.map(st => `store=${encodeURIComponent(st)}`).join('&') || ''
		const brandParam = brand ? `&brand=${encodeURIComponent(brand)}` : ''
		const discountParam = on_sale
			? `&on_sale=${encodeURIComponent(on_sale)}`
			: ''

		const url = `/resources/productCarouselDataClerk?endpoint=${encodeURIComponent(
			recommendation,
		)}${categoryParams ? `&${categoryParams}` : ''}${
			storeParams ? `&${storeParams}` : ''
		}${brandParam}${discountParam}`

		try {
			const response = await fetch(url)
			if (!response.ok) {
				throw new Error(`Network response was not ok: ${response.statusText}`)
			}
			return await response.json()
		} catch (error) {
			console.error('Failed to fetch products:', error)
			return []
		}
	}

	useEffect(() => {
		const fetchAllProducts = async () => {
			const productsMap = new Map<string, ClerkProduct[]>()
			await Promise.all(
				blok.tabs.map(async tab => {
					const products = await fetchProductsForTab(tab)
					productsMap.set(tab.key, products)
				}),
			)
			setTabProducts(productsMap)
		}

		fetchAllProducts()
	}, [blok.tabs])

	const formattedTabs = blok.tabs.map(tab => ({
		label: tab.label,
		key: tab.key,
		linkValue: tab.linkValue,
		products: {
			clerkProducts: tabProducts.get(tab.key),
		},
	}))

	return (
		<TabProductCarousel
			tabs={formattedTabs}
			title={blok.title}
			linkText={blok.linkText}
			storeParams={blok.tabs[0]?.content[0].store}
			twoRows={blok.twoRows}
		/>
	)
}

import { useLoaderData } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { type StoreHeroImageStoryblok } from '#types/component-types-sb'
import ButtonAndLinks from '../typography/button-and-links'
import PlentyBody from '../typography/plenty-body'
import PlentyHeading from '../typography/plenty-heading'
import { Button } from '../ui/button'
import { Icon } from '../ui/icon'
interface OpeningHoursData {
	openingTime: {
		day: string
		openingTime: string
		closingTime: string
	}[]
}

const StoreHeroImage = ({ blok }: StoreHeroImageStoryblok) => {
	const { openingTime }: OpeningHoursData = useLoaderData()
	let { t } = useTranslation('store_page')
	const imageUrlDesktop = blok.storeHeroImageDesktop?.filename || ''
	const imageUrlMobile = blok.storeHeroImageMobile?.filename || imageUrlDesktop
	const textColor = blok.whiteText ? 'text-black' : 'text-white'

	const currentDay = new Date().getDay()

	const daysOfWeek = [
		t('common:sunday_tag'),
		t('common:monday_tag'),
		t('common:tuesday_tag'),
		t('common:wednesday_tag'),
		t('common:thursday_tag'),
		t('common:friday_tag'),
		t('common:saturday_tag'),
	]
	const handleGoogleMapsButtonClick = () => {
		// Directly using window.open to open the URL in a new tab
		window.open(blok.storeGoogleMapsUrl, '_blank', 'noopener,noreferrer')
	}
	return (
		<div className="relative z-10 w-full overflow-hidden">
			<picture>
				{imageUrlMobile && (
					<source media="(max-width: 819px)" srcSet={imageUrlMobile} />
				)}
				{imageUrlDesktop && (
					<source media="(min-width: 820px)" srcSet={imageUrlDesktop} />
				)}
				<img
					src={imageUrlDesktop}
					alt="Store Page Schedule"
					fetchpriority="high"
					className="min-h-[365px] w-full object-cover md:min-w-[650px]"
				/>
			</picture>
			<div className="absolute inset-0 flex flex-col items-center justify-center px-4 py-8 md:inset-auto md:right-[25%] md:top-[72px] md:px-0 md:py-0">
				{/* Adjusted to use flexbox centering for mobile. For desktop, adjust as needed. */}
				<div className="mb-4 w-full text-left md:w-full md:text-right">
					<PlentyHeading as="h5" className={`${textColor}`}>
						{t('opening_hours_header')}
					</PlentyHeading>
					<PlentyBody size="sm" className={`${textColor}`}>
						{blok.adress}
					</PlentyBody>
				</div>
				{openingTime?.map((hour, index) => (
					<div key={index} className="flex w-full justify-between md:gap-8">
						<PlentyBody
							size="md"
							className={`w-24 text-left ${textColor} ${
								hour.day === daysOfWeek[currentDay] ? 'font-bold' : ''
							}`}
						>
							{hour.day}
						</PlentyBody>
						<PlentyBody
							size="md"
							className={`${textColor} ${
								hour.day === daysOfWeek[currentDay] ? 'font-bold' : ''
							}`}
						>
							{hour.openingTime} - {hour.closingTime}
						</PlentyBody>
					</div>
				))}
				<div className="mx-auto  w-full max-w-[1920px] pt-2">
					<Button
						size="primary"
						variant={'secondary'}
						className="group w-full gap-[4px]"
						onClick={handleGoogleMapsButtonClick} // Use onClick to handle button click
					>
						<Icon
							className="text-body-md text-black group-hover:text-white"
							size="lg"
							name="map"
						/>
						<ButtonAndLinks as="s" body={t('get_directions')} />
					</Button>
				</div>
			</div>
			{!imageUrlDesktop && <p>Image not available</p>}
		</div>
	)
}

export default StoreHeroImage

import { useFetcher } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { useEffect } from 'react'
import ShopTheLookSimpleLandingCarousel from '#app/features/shop-the-look/simple-slider/shop-the-look-simple-landing-carousel'
import { type action } from '#app/routes/resources+/storyblok+/getOutfits.tsx'
import { type ShopTheLookSimpleSliderStoryblok } from '#types/component-types-sb'

interface ShopTheLookSliderSimpleProps {
	blok: ShopTheLookSimpleSliderStoryblok
}

const ShopTheLookSliderSimple = ({ blok }: ShopTheLookSliderSimpleProps) => {
	const outfitsByStyle = useFetcher<typeof action>()

	const gender = blok.gender ?? ''
	const store = blok.store ?? ''
	const sort = blok.sort ?? ''
	useEffect(() => {
		if (!outfitsByStyle.data && outfitsByStyle.state === 'idle') {
			const formData = new FormData()
			formData.append('gender', gender)
			formData.append('store', store)
			formData.append('sort', sort)
			outfitsByStyle.submit(formData, {
				method: 'post',
				action: '/resources/storyblok/getOutfits',
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!outfitsByStyle.data) {
		return null
	}

	return (
		<div {...storyblokEditable(blok)}>
			<ShopTheLookSimpleLandingCarousel
				outfitsByStyle={outfitsByStyle.data.outfitsByStyle}
				textColorAmountOfProducts={blok.textColorAmountOfProducts}
			/>
		</div>
	)
}

export default ShopTheLookSliderSimple

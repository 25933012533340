import { WheelGesturesPlugin } from '#node_modules/embla-carousel-wheel-gestures'
import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import ImageComponent from '#app/components/common/image-component'
import PlentyBody from '#app/components/typography/plenty-body.tsx'
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from '#app/components/ui/carousel.tsx'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { type Outfit } from '#types/outfit.ts'

interface ShopTheLookSimpleCarouselComponentProps {
	outfitsByStyle: Outfit[]
	textColorAmountOfProducts?: string
}

const ShopTheLookSimpleCarouselComponent = ({
	outfitsByStyle,
	textColorAmountOfProducts,
}: ShopTheLookSimpleCarouselComponentProps) => {
	let { t } = useTranslation('shop_the_look')
	const { trackAmplitudeEvent } = useAmplitudeContext()

	// Convert color name to Tailwind class
	const getTextColorClass = (color?: string) => {
		if (!color) return ''
		return `text-${color}`
	}

	const textColorClass = getTextColorClass(textColorAmountOfProducts)

	return (
		<>
			<Carousel
				plugins={[WheelGesturesPlugin()]}
				opts={{ dragFree: true }}
				className="w-full max-w-full overflow-hidden"
			>
				<CarouselContent className="-ml-4">
					{outfitsByStyle.map((outfit: any, index: any) => (
						<CarouselItem
							key={index}
							className="flex max-w-[168px] flex-col gap-2"
						>
							<Link
								to={`/shop-the-look/${outfit.key}`}
								className="flex flex-row justify-center md:justify-start"
								onClickCapture={() => {
									trackAmplitudeEvent('simple shop the look clicked', {
										outfitName: outfit.name,
										outfitKey: outfit.key,
									})
								}}
							>
								{outfit.mediaType === 'VIDEO' ? (
									<video
										src={outfit.mediaUrl}
										autoPlay
										muted
										loop
										playsInline
										className="h-[220px] w-full rounded-lg object-cover"
									/>
								) : (
									<div>
										<ImageComponent
											src={outfit.mediaUrl}
											alt={outfit.name}
											className="h-[220px] w-full rounded-lg object-cover"
										/>
									</div>
								)}
							</Link>
							<div className="flex flex-col">
								<PlentyBody className="text-beige-100 ">
									{outfit.name}
								</PlentyBody>
								<PlentyBody className={textColorClass}>
									{outfit.styleVariants?.length || 0}{' '}
									{outfit.styleVariants?.length === 1
										? t('product')
										: t('products')}
								</PlentyBody>
							</div>
						</CarouselItem>
					))}
				</CarouselContent>
			</Carousel>
		</>
	)
}

export default ShopTheLookSimpleCarouselComponent
